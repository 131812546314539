import axios from "axios";

export default function userUtils() {
  // check otp
  const checkOTP = async ({
    inputOTP,
    phoneNumber,
    hashOtp, // serverRes.data.hash
    setIsLoginModal,
    setAuthPage,
    setInitialState,
  }) => {
    const res = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/api/users/checkotp/`,
      { otp: inputOTP, hashOtp, phoneNumber }
    );

    if (res.data.isSuccess) {
      if (res.data.isRegistered) {
        localStorage.setItem("user", JSON.stringify(res.data.user));
        setInitialState((p) => ({
          ...p,
          user: res.data.user,
          isLoading: false,
        }));
        setAuthPage({
          login: true,
          otp: false,
          register: false,
        });
        setIsLoginModal(false);
        window.location.href = "/";
        // window.toast("Logged in successfully");
      } else {
        // window.toast("OTP Verified successfully");
        setIsLoginModal(true);

        setAuthPage({
          otp: false,
          login: false,
          register: true,
        });
      }
      return { matched: true };
    } else {
      return { matched: false };
    }
  };

  return {
    checkOTP,
  };
}

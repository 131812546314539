import React, { useContext, useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import CompRenderingContext from "../../../../context/CompRenderingContext";
import { FaAngleLeft } from "react-icons/fa";
import TextSearchBar from "./subComponents/TextSearchBar";
import ServiceAvailable from "./subComponents/ServiceAvailable";
import DefaultLocationSuggestions from "./subComponents/DefaultLocationSuggestions";
import WarningModalv2 from "../../../../assets/WarningModalv2";
import Map from "./subComponents/Map";
import GLobalStoreContext from "../../../../context/GlobalStoreContext";
import AddressContext from "../../../../context/AddressContext";

let queryDebounceTime = 600;
let autocompleteService;
let country = "IN";
let timeoutId;
let autoCompleteRef = null;
let mapContainerRef = null;
let minSearchTextLengthToStartSearch = 4;

export default function AddLocationsIdx() {
  const { locationData, setLocationData } = useContext(CompRenderingContext);

  return (
    <AnimatePresence>
      {locationData.isLocationAddModalRender && (
        <Modal setLocationData={setLocationData} locationData={locationData} />
      )}
    </AnimatePresence>
  );
}

const Modal = ({ setLocationData, locationData }) => {
  const { storeStates, setStoreStates, sendMessageToNative } =
    useContext(GLobalStoreContext);
  const { setSelectedAddress } = useContext(AddressContext);

  const isSmallDevice = true;

  const [states, setStates] = useState({
    lat: locationData.selectedLocation?.lat || 20,
    lng: locationData.selectedLocation?.lng || 82,
    map: null,
    markers: {},
    address: "",
    placeId: "",
    predictions: [],
    query: "",
    hubId: "",
    isPredictionSelectedLocation: false,
    currentLiveLocation: null,
    defaultUi: locationData.isAddressSaveModal ? false : true,
    isChangeMapPosition: false,
    mapScriptLoaded: false,
    loadingCurrentLocation: false,
    searchBarResultAddress: "",
    isPredictionsShow: false,
    loadingPredictions: false,
    loadingPointLocation: false,
    showServiceCites: true,
    loadingReverseGeoCode: false,
    showMap: locationData.isAddressSaveModal ? true : false,
    serviceAvailable: false,
    noServiceAvailable: false,
    loadingLocation: false,
    warningModel: {
      msg: "",
      leftBtnText: "",
      rightBtnText: "",
      isRender: false,
      funOnLeftBtn: () => {},
      funOnRightBtn: () => {},
    },
  });

  useEffect(() => {
    if (storeStates?.currentLocation?.lat) {
      const lat = storeStates?.currentLocation?.lat;
      const lng = storeStates?.currentLocation?.lng;
      setStates((p) => ({
        ...p,
        isChangeMapPosition: true,
        showMap: true,
        lat,
        lng,
        currentLiveLocation: { lat, lng },
        loadingCurrentLocation: false,
        defaultUi: false,
      }));

      setStoreStates((p) => ({ ...p, currentLocation: null }));
    }
    // eslint-disable-next-line
  }, [storeStates?.currentLocation?.lat]);

  useEffect(() => {
    onFetchClick();
    return () => {
      const markers = states.markers;
      for (const key in markers) {
        delete markers[key];
      }
    };

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // Check if the script is already loaded
    if (!storeStates.isGoogleMapsScriptLoaded) {
      // Load the Google Maps API script
      const script = document.createElement("script");
      script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_KEY}&libraries=places`;
      script.defer = true;
      document.head.appendChild(script);

      // Set the global variable to true once the script is loaded
      script.onload = () => {
        setStoreStates((p) => ({
          ...p,
          isGoogleMapsScriptLoaded: true,
        }));
        initMap();
      };

      // Cleanup to prevent memory leaks
      return () => {
        document.head.removeChild(script);
      };
    } else {
      // The script is already loaded, perform any necessary actions
      initMap();
    }

    // eslint-disable-next-line
  }, []);

  const initMap = () => {
    setStates((p) => ({ ...p, mapScriptLoaded: true }));
    // initializing the autocomplete service for getting predictions
    autocompleteService = new window.google.maps.places.AutocompleteService();
  };

  const onFetchClick = () => {
    setStates((p) => ({
      ...p,
      loadingCurrentLocation: true,
    }));

    sendMessageToNative({
      action: "requestLocation",
    });

    return;
  };

  const handleClose = () => {
    if (!states.defaultUi) {
      setStates((p) => ({
        ...p,
        defaultUi: true,
        loadingCurrentLocation: false,
        isPredictionsShow: false,
        loadingPredictions: false,
        loadingPointLocation: false,
        showServiceCites: true,
        loadingReverseGeoCode: false,
        serviceAvailable: false,
        noServiceAvailable: false,
        loadingLocation: false,
      }));
    } else {
      setLocationData((p) => ({
        ...p,
        isLocationAddModalRender: false,
        isAddressSaveModal: false,
      }));
    }
  };
  const handleConfirmLocation = () => {
    try {
      const addressLine1 =
        states.searchBarResultAddress?.split(",")[0] ||
        states.premise ||
        states.streetName ||
        states.route ||
        states.s_locality1 ||
        states.s_locality2 ||
        states.s_locality3 ||
        states.city ||
        states.pincode;
      // if the location is changed we will set the address as null and cart address as null as well
      setSelectedAddress(null);

      const selectedLocation = {
        lat: states.lat,
        hubId: states.hubId,
        lng: states.lng,
        addressLine1,
        city: states.city,
        state: states.state,
        pincode: states.pincode,
        addressLine2: states.searchBarResultAddress || states.formatedAddress,
      };

      if (locationData.isAddressSaveModal) {
        if (locationData.addAndModiAddress.options.isEdit) {
          setLocationData((p) => ({
            ...p,
            isLocationAddModalRender: false,
            addAndModiAddress: {
              ...p.addAndModiAddress,
              isRender: true,
            },
            selectedLocation,
          }));
          return;
        }

        setLocationData((p) => ({
          ...p,
          isLocationAddModalRender: false,
          addAndModiAddress: {
            ...p.addAndModiAddress,
            isRender: true,
          },
          selectedLocation,
        }));
        return;
      }
      localStorage.setItem("U_LOCATION", JSON.stringify(selectedLocation));
      setLocationData((p) => ({
        ...p,
        isLocationAddModalRender: false,
        selectedLocation,
      }));
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="fixed right-0 top-0 left-0 bottom-0 z-[999]">
      <div
        onClick={() => {
          setLocationData((p) => ({
            ...p,
            isLocationAddModalRender: false,
          }));
        }}
        className="fixed right-0 top-0 left-0 bottom-0 z-[-1] bg-[#00000026]"
      ></div>
      <motion.div
        initial={
          isSmallDevice
            ? { translateY: 500, opacity: 0 }
            : { translateX: -500, opacity: 0 }
        }
        animate={
          isSmallDevice
            ? { translateY: 0, opacity: 1 }
            : { translateX: 0, opacity: 1 }
        }
        exit={
          isSmallDevice
            ? { translateY: 500, opacity: 0 }
            : { translateX: -500, opacity: 0 }
        }
        transition={{ duration: 0.3 }}
        className="w-full text-[.875rem] flex flex-col items-center md:border-r border-gray-200 max-w-[30rem] bg-[white] fixed top-0 left-0 bottom-0 z-[2] "
      >
        <div className="flex w-full gap-2 items-center bg-[white] p-4 pb-2 pt-3 z-[4] text-[1.125rem] font-[500] ">
          <FaAngleLeft onClick={handleClose} className="text-[1.25rem]" />
          <div>Select service location</div>
        </div>

        {/* location search bar (position: absolute) */}
        <TextSearchBar
          minSearchTextLengthToStartSearch={minSearchTextLengthToStartSearch}
          states={states}
          setStates={setStates}
          timeoutId={timeoutId}
          queryDebounceTime={queryDebounceTime}
          autocompleteService={autocompleteService}
          country={country}
        />

        <WarningModalv2 parentStates={states} setParentStates={setStates} />

        {/* the default initial ui (including the prediction search list) */}
        <DefaultLocationSuggestions
          locationData={locationData}
          states={states}
          setStates={setStates}
          onFetchClick={onFetchClick}
        />

        {/* if the selected location on the map is serviceable */}
        <ServiceAvailable
          states={states}
          handleConfirmLocation={handleConfirmLocation}
          setStates={setStates}
          autoCompleteRef={autoCompleteRef}
          onFetchClick={onFetchClick}
        />

        {/* the main google map */}
        {states.showMap && (
          <Map
            states={states}
            setStates={setStates}
            mapContainerRef={mapContainerRef}
          />
        )}
      </motion.div>
    </div>
  );
};

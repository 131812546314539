import { useContext, useEffect, useState } from "react";
import HomePageContext from "../context/HomepageContext";
import AddressContext from "../context/AddressContext";
import OfferContext from "../context/OfferContext";
import AuthContext from "../context/AuthContext";
import CartContext from "../context/CartContext";
import { handleDefaultLocationFetch } from "../functions/location/handleLocation";
import CompRenderingContext from "../context/CompRenderingContext";
import { logUserLocationApi } from "../api/userApi";
import { isBrowser, isMobile } from "react-device-detect";
import { v2Api } from "../api/v2.api";
import { useStoreContext } from "../context/StoreContext";
import eboUtils from "../utils/eboUtils";

const useFetchEboData = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { fetchHomeData, homeInitialData } = useContext(HomePageContext);
  const { user } = useContext(AuthContext);
  const { handleCartPresence } = useContext(CartContext);
  const { fetchAddressData } = useContext(AddressContext);
  const { setUI } = useStoreContext();

  const { offerState, getOffers } = useContext(OfferContext);
  const { setLocationData, locationData } = useContext(CompRenderingContext);
  useEffect(() => {
    callMethods();
    // eslint-disable-next-line
  }, [user?.id]);

  async function callMethods() {
    setIsLoading(true);
    setUI((p) => ({
      ...p,
      home: {
        ...p.home,
        nav: {
          ...p.home.nav,
          backgroundThemeMode: "light",
          isDropBannerVisible: false,
        },
      },
    }));
    const ipInfo = await logUserLocationApi({ isUserLoggedIn: user }); // user traffic
    await handleUserLogin(ipInfo);
    const { hubId, locationObject } = await handleDefaultLocationFetch(
      locationData,
      ipInfo
    );
    let validAddressToAdd = locationObject;

    hubId && (await fetchHomeData({ hubId }));
    if (user) {
      const userSavedAddresses = await fetchAddressData();
      const userCart = await handleCartPresence();
      if (userCart?.bookingAddress || userSavedAddresses?.[0]) {
        validAddressToAdd = userCart?.bookingAddress || userSavedAddresses?.[0];

        validAddressToAdd = {
          ...(validAddressToAdd?.locationv2 || {}),
          address1: validAddressToAdd?.address1,
          address2: validAddressToAdd?.address2,
          addressLabel: validAddressToAdd?.addressLabel,
          receiversDetails: validAddressToAdd?.receiversDetails,
        };

        const serviceAreaInfo = await eboUtils().getServiceAreaInfo({
          lat: validAddressToAdd?.lat,
          lng: validAddressToAdd?.lng,
        });

        if (serviceAreaInfo.isServiceable) {
          validAddressToAdd = {
            ...validAddressToAdd,
            hubId: serviceAreaInfo.hubs?.[0]?.hubId,
          };
        }
      }
    }

    setTimeout(() => {
      setUI((p) => ({
        ...p,
        home: {
          ...p.home,
          nav: {
            ...p.home.nav,
            backgroundThemeMode: "dark",
            isDropBannerVisible: true,
          },
        },
      }));
    }, 1000);

    // setting up the location data
    setLocationData((p) => ({
      ...p,
      selectedLocation: validAddressToAdd,
    }));

    if (offerState.offers?.length === 0 || !offerState.offers) {
      await getOffers();
    }

    setIsLoading(false);
  }

  const handleUserLogin = async (ipInfo) => {
    try {
      if (!ipInfo) {
        ipInfo = sessionStorage.getItem("trafficInfo")
          ? JSON.parse(sessionStorage.getItem("trafficInfo"))
          : {};
      }
      const options = {
        tags: ["user", "userLogin"],
        data: {
          registered: user?.id ? true : false,
          ipInfo: ipInfo,
          userId: user?.id,
          device: isMobile ? "mobile" : isBrowser ? "browser" : null,
          src: "site",
        },
      };

      await v2Api(options);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    async function callMethods() {
      homeInitialData?.initialData &&
        locationData?.selectedLocation?.hubId &&
        (await fetchHomeData({ hubId: locationData?.selectedLocation?.hubId }));
    }
    callMethods();
    // eslint-disable-next-line
  }, [locationData?.selectedLocation?.hubId]);

  return { callMethods, isLoading };
};

export default useFetchEboData;

import React, { useContext, useState } from "react";
import { AiTwotoneEdit } from "react-icons/ai";
import { FaLocationArrow, FaMobileAlt } from "react-icons/fa";
import { MdDelete, MdOutlineWorkOutline } from "react-icons/md";
import CompRenderingContext from "../../../context/CompRenderingContext";
import AddressContext from "../../../context/AddressContext";
import { HiOutlineUserGroup } from "react-icons/hi2";
import { GoHome } from "react-icons/go";
import { IoLocationOutline } from "react-icons/io5";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { TbLocationFilled } from "react-icons/tb";
import CartContext from "../../../context/CartContext";
import WarningModalv2 from "../../../assets/WarningModalv2";
import eboUtils from "../../../utils/eboUtils";

export default function StructuredAddressContainer({
  address,
  hideEditRemove,
  hideBorder,
  isSelecteAddress,
  hideChange,
  type = "addressBox", // navbarAdd
  onClickCallback,
  hideCheckIcon,
  isTempCartAddressSelect,
}) {
  const {
    setWarningModal,
    setLocationData,
    locationData,
    setIsAddressSelector,
  } = useContext(CompRenderingContext);
  const { removeAddress, selectedAddress, setSelectedAddress } =
    useContext(AddressContext);
  const { temp_cart_booking_details, tempCart } = useContext(CartContext);
  const [states, setStates] = useState({
    warningModel: {
      msg: "",
      leftBtnText: "",
      rightBtnText: "",
      isRender: false,
      funOnLeftBtn: () => {},
      funOnRightBtn: () => {},
    },
  });

  const handleEditAddressPopUpModalToggle = (editingAddressInfo) => {
    setLocationData((p) => ({
      ...p,
      selectedLocation: editingAddressInfo.locationv2,
      isLocationAddModalRender: true,
      isAddressSaveModal: true,
      addAndModiAddress: {
        isRender: false,
        options: {
          customZIndex: 15,
          isEdit: true,
          editingAddressInfo,
        },
      },
    }));
  };
  const handleOpenLocationModal = () =>
    setLocationData((p) => ({
      ...p,
      isLocationAddModalRender: true,
    }));

  const handleRemove = (id) => {
    setWarningModal({
      text: "Do You realy want to remove?",
      isRender: true,
      funToBeExecuted: () => {
        removeAddress(id);
      },
      funToBeExecutedOnNo: () => {},
    });
  };

  const addresslabel = address?.addressLabel.trim().toLowerCase();

  const isSelected = selectedAddress?._id === address?._id;

  const handleAddressSelect = async () => {
    const serviceAreaInfo = await eboUtils().getServiceAreaInfo({
      lat: address?.locationv2?.lat,
      lng: address?.locationv2?.lng,
    });

    if (serviceAreaInfo.isServiceable) {
      if (tempCart) {
        temp_cart_booking_details({ bookingAddress: address });
      }

      setSelectedAddress(address);
      setLocationData((p) => ({
        ...p,
        selectedLocation: {
          ...address.locationv2,
          hubId: serviceAreaInfo.hubs?.[0]?.hubId,
        },
      }));
      onClickCallback(address);
    } else {
      setStates((p) => ({
        ...p,
        warningModel: {
          msg: "Address you selected is not serviceable",
          rightBtnText: "Ok",
          isRender: true,
        },
      }));
    }
  };

  if (type === "navbarAdd") {
    return (
      <NavBarAddressStrip1
        selectedAddress={selectedAddress}
        setLocationData={setLocationData}
        handleOpenLocationModal={handleOpenLocationModal}
        locationData={locationData}
      />
    );
  }
  if (type === "a_r2") {
    // payment page address representation
    return (
      <SelectedAddressRepresentation2
        address={address}
        addresslabel={addresslabel}
      />
    );
  }

  if (type === "a_r1") {
    // address representation 1
    return (
      <SelectedAddressRepresentation1
        address={address}
        hideChange={hideChange}
        addressLabel={addresslabel}
        setIsAddressSelector={setIsAddressSelector}
        addresslabel={addresslabel}
        onClickCallback={onClickCallback}
        handleOpenLocationModal={handleOpenLocationModal}
      />
    );
  }
  if (type === "b_a_r1") {
    // booked order address representation 1
    return <BookingAddressR1 address={address} addressLabel={addresslabel} />;
  }
  if (type === "l_r1") {
    // location representation 1
    return (
      <SelectedLocationRepresentation1
        handleOpenLocationModal={handleOpenLocationModal}
        locationData={locationData}
        setIsAddressSelector={setIsAddressSelector}
        addresslabel={addresslabel}
      />
    );
  }

  return (
    <>
      <WarningModalv2 parentStates={states} setParentStates={setStates} />
      <div
        onClick={() => {
          isSelecteAddress && handleAddressSelect();
          !isSelecteAddress && onClickCallback && onClickCallback(address);
        }}
        style={{
          border: hideBorder ? "none" : "1px solid",
          padding: hideBorder ? "0px" : "1rem",
          marginBottom: hideBorder ? "0px" : "0.5rem",
          borderColor: isSelecteAddress && isSelected ? "#c7c7ff" : "#d9d9d9",
        }}
        className={` ${
          isSelecteAddress && isSelected && "bg-blue-50 "
        } w-full flex gap-2 relative text-[.825rem] rounded-lg   font-[500]  `}
      >
        {!hideCheckIcon && (
          <div className="address-slection-btn  right-[1rem] top-[1rem] flex">
            {isSelected && <CheckCircleIcon className=" w-36 " />}
          </div>
        )}

        {addresslabel === "home" ? (
          <GoHome className="text-[2rem] text-[#2136d4]" />
        ) : addresslabel === "work" ? (
          <MdOutlineWorkOutline className="text-[2rem] text-[#2136d4]" />
        ) : addresslabel === "friends and family" ? (
          <HiOutlineUserGroup className="text-[2rem] text-[#2136d4]" />
        ) : (
          <IoLocationOutline className="text-[2rem] text-[#2136d4]" />
        )}

        <div className="flex flex-col w-full text-[.825rem] text-[grey] ">
          <div className="flex gap-2 mb-1 text-[black] capitalize items-center ">
            <span className="font-[600] capitalize text-[1.125rem]">
              {address?.receiversDetails?.name}
            </span>
            <span className="border bg-gray-50 border-gray-200 text-[gray] text-[.625rem]  rounded px-2 ">
              {address?.addressLabel}
            </span>
          </div>

          <div className=" leading-[18px] text-[gray] font-[400]  tracking-[.4px]">
            {address?.address1 + ", "}
            {address?.address2 && address?.address2 + ", "}
            {address?.locationv2?.addressLine1}
          </div>
          <div className=" leading-[18px] font-[400] text-[gray] tracking-[.4px]">
            {address?.locationv2?.addressLine2}
          </div>
          <div className="flex  gap-[2px] mt-2 items-center text-[gray] font-[400]">
            <FaMobileAlt className=" relative bottom-[.5px] text-[.625rem]" />{" "}
            {address?.receiversDetails?.mobileNumber}
          </div>
          {!hideEditRemove && (
            <div className="w-full flex mt-2 text-[grey] text-[.825rem] justify-between ">
              <button
                onClick={() => {
                  handleRemove(address?._id);
                }}
              >
                <MdDelete className="relative cursor-pointer top-[2px]" />
                <span> Remove</span>
              </button>
              <button
                onClick={() => {
                  handleEditAddressPopUpModalToggle(address);
                }}
              >
                <AiTwotoneEdit className="relative cursor-pointer top-[2px]" />{" "}
                <span> Edit</span>
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

const SelectedAddressRepresentation1 = ({
  address,
  addresslabel,
  onClickCallback,
  hideChange,
  handleOpenLocationModal,
}) => {
  const line2 =
    address?.address1 +
    ", " +
    (address?.address2 && address?.address2 + ", ") +
    address?.locationv2?.addressLine1 +
    ", " +
    address?.locationv2?.addressLine2;
  return (
    <div
      onClick={() => {
        onClickCallback ? onClickCallback(address) : handleOpenLocationModal();
      }}
      className="flex cursor-pointer  
    border-[#eeeeee]  text-[.875rem] items-start justify-between"
    >
      <div className="flex flex-col">
        <div className="flex gap-2 items-center">
          <FaLocationArrow className="text-[#2136d4] text-[.825rem] " />

          <div className="flex gap-1 items-center">
            <span className="text-[grey]">Service at </span>
            <span className="text-[black] font-[500] max-w-[6rem] overflow-hidden overflow-ellipsis whitespace-nowrap">
              {address.receiversDetails?.name}
            </span>
            <div className="flex items-center text-[.625rem] rounded px-1 ml-1  py-[1px] capitalize font-[500] gap-[2px] bg-[#F0F5FE]">
              {addresslabel === "home" ? (
                <GoHome className="text-[.725rem] relative bottom-[.5px] " />
              ) : addresslabel === "work" ? (
                <MdOutlineWorkOutline className="text-[1rem] relative  bottom-[.5px]" />
              ) : (
                <IoLocationOutline className="text-[1rem] text-[#2136d4] relative bottom-[.5px]" />
              )}
              <span className="max-w-[3rem] capitalize overflow-hidden overflow-ellipsis whitespace-nowrap">
                {addresslabel}
              </span>
            </div>
          </div>
        </div>
        <span className="text-[grey] text-[.75rem] max-w-[15rem] overflow-hidden overflow-ellipsis whitespace-nowrap">
          {line2}
        </span>
      </div>
      {!hideChange && (
        <button className=" text-[#2136d4] px-2 py-[2px] inline-block font-[500] text-[.725rem]">
          Change
        </button>
      )}
    </div>
  );
};
const SelectedAddressRepresentation2 = ({ address, addresslabel }) => {
  const line2 =
    address?.address1 +
    ", " +
    (address?.address2 && address?.address2 + ", ") +
    address?.locationv2?.addressLine1 +
    ", " +
    address?.locationv2?.addressLine2;
  return (
    <>
      <TbLocationFilled className="text-[#2136d4] text-[1.75rem] relative top-[6px]" />
      <div className="flex flex-col">
        <div className="flex gap-2 items-center">
          <div className="flex gap-1 items-center">
            <span className="text-[grey]">Service at</span>
            <span className="text-[black] font-[500] max-w-[8rem] overflow-hidden overflow-ellipsis whitespace-nowrap">
              {address.receiversDetails?.name}
            </span>
            <div className="flex items-center text-[.625rem] rounded px-1 py-[1px] capitalize font-[500] gap-[2px] bg-[#F0F5FE]">
              {addresslabel === "home" ? (
                <GoHome className="text-[.725rem] relative bottom-[.5px] " />
              ) : addresslabel === "work" ? (
                <MdOutlineWorkOutline className="text-[1rem] relative  bottom-[.5px]" />
              ) : (
                <IoLocationOutline className="text-[1rem] text-[#2136d4] relative bottom-[.5px]" />
              )}
              <span className="max-w-[3rem] overflow-hidden overflow-ellipsis whitespace-nowrap">
                {addresslabel}
              </span>
            </div>
          </div>
        </div>
        <div className="text-[grey] text-[.8125rem] max-w-[20rem] w-[20rem] overflow-hidden overflow-ellipsis whitespace-nowrap">
          {line2}
        </div>
      </div>
    </>
  );
};

const BookingAddressR1 = ({ address, addressLabel }) => {
  const line2 =
    address?.address1 +
    ", " +
    (address?.address2 && address?.address2 + ", ") +
    address?.locationv2?.addressLine1 +
    ", " +
    address?.locationv2?.addressLine2;
  return (
    <div className="w-full flex gap-2 pl-5 text-[.875rem] font-[500]  ">
      <div className="flex flex-col w-full text-[.875rem] text-[grey] ">
        <div className="flex gap-2 text-[black] items-center ">
          <span className="font-[500] text-[1rem] capitalize ">
            {address.receiversDetails?.name}
          </span>
          <div className="flex items-center text-[.625rem] rounded px-1 py-[1px] capitalize font-[500] gap-[2px] bg-[#F0F5FE]">
            {addressLabel === "home" ? (
              <GoHome className="text-[.725rem] relative bottom-[.5px] " />
            ) : addressLabel === "work" ? (
              <MdOutlineWorkOutline className="text-[1rem] relative  bottom-[.5px]" />
            ) : (
              <IoLocationOutline className="text-[1rem] text-[#2136d4] relative bottom-[.5px]" />
            )}
            <span className="max-w-[3rem] overflow-hidden overflow-ellipsis whitespace-nowrap">
              {addressLabel}
            </span>
          </div>
        </div>
        <div className=" leading-[18px] font-[400] capitalize text-[gray] tracking-[.4px]">
          {line2}
        </div>
        <div className="flex gap-[2px] mt-1 items-center text-[gray] font-[400]">
          <FaMobileAlt className=" relative bottom-[.5px] text-[.65rem]" />{" "}
          {address.receiversDetails?.mobileNumber}
        </div>
      </div>
    </div>
  );
};
const SelectedLocationRepresentation1 = ({
  locationData,
  onClickCallback,
  handleOpenLocationModal,
}) => {
  return (
    <div
      onClick={() => {
        onClickCallback
          ? onClickCallback(locationData)
          : handleOpenLocationModal();
      }}
      className="flex cursor-pointer  
    border-[#eeeeee]  text-[.875rem] items-start justify-between"
    >
      <div className="flex flex-col">
        <div className="flex gap-2 items-center">
          <FaLocationArrow className="text-[#2136d4] text-[.825rem] " />

          <div className="flex gap-1 items-center">
            <span className="text-[grey]">Service at </span>
            <span className="text-[black] font-[500] max-w-[6rem] overflow-hidden overflow-ellipsis whitespace-nowrap">
              {locationData?.selectedLocation?.addressLine1}
            </span>
          </div>
        </div>
        <span className="text-[grey] text-[.75rem] max-w-[15rem] overflow-hidden overflow-ellipsis whitespace-nowrap">
          {locationData?.selectedLocation?.addressLine2}
        </span>
      </div>
      <button className=" text-[#2136d4] px-2 py-[2px] inline-block font-[500] text-[.725rem]">
        Change
      </button>
    </div>
  );
};

const NavBarAddressStrip1 = ({
  selectedAddress,
  handleOpenLocationModal,
  locationData,
}) => {
  const line1 = selectedAddress
    ? selectedAddress.addressLabel
    : locationData?.selectedLocation?.addressLine1;
  const line2 = selectedAddress
    ? selectedAddress?.address1 +
      ", " +
      (selectedAddress?.address2 && selectedAddress?.address2 + ", ") +
      selectedAddress?.locationv2?.addressLine1 +
      ", " +
      selectedAddress?.locationv2?.addressLine2
    : locationData?.selectedLocation?.addressLine2;

  return (
    <div className="flex tracking-wide pt-1 w-full items-center justify-between  ">
      <div
        onClick={handleOpenLocationModal}
        className="flex item-center text-[.825rem] flex-col w-full justify-start "
      >
        <div className="flex flex-col w-full ">
          <div className="font-[600] flex items-center gap-2 capitalize text-[1rem] ">
            <FaLocationArrow className="text-[#2136d4] text-[1rem]" />
            {line1}
          </div>
          <div className=" overflow-hidden text-ellipsis w-full whitespace-nowrap font-[400] text-[grey] text-[.825rem] leading-4 ">
            {line2}
          </div>
        </div>
      </div>
    </div>
  );
};

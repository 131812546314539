import moment from "moment";

// Create a new tempCart with initial values
const create_temp_cart = async ({
  bookingDate,
  bookingAddress,
  bookingTime,
  hubId,
  product,
  user,
  santaTip,
  convenienceFee,
  getDecorOccassionCategoryName,
  readyTime,
  arrivingTime,
  celebrationDate,
}) => {
  if (!hubId) return null;

  const celebratingFor = !user
    ? {}
    : {
        name: user.name,
        age: moment().year() - moment(user.dateOfBirth, "DD-MM-YYYY").year(),
        occasion: getDecorOccassionCategoryName(product?.ocassionCategory),
      };
  const productPriceDetail = product?.listingPriceHubWise?.find(
    (currHub) => currHub.hub === hubId
  );

  const cart = {
    addOns: [],
    instructions: "",
    santaTip: santaTip || 0,
    bookingDate: bookingDate || "",
    bookingAddress: bookingAddress || "",
    bookingTime: bookingTime || "",
    product,
    buyingPrice: productPriceDetail?.sellingPrice,
    cartPrice: productPriceDetail?.costPrice,
    cartPaymentAttemps: 0,
    convenienceFee: convenienceFee || 0,
    celebratingFor,
    offers: [],
    freebie: {},
    readyTime: readyTime || "",
    arrivingTime: arrivingTime || "",
    celebrationDate: celebrationDate || "",
  };

  localStorage.setItem("LC_TEMP_CART", JSON.stringify(cart));

  return cart;
};

// If cart is already present in the database, then convert it to a temp cart
const existing_cart_to_temp_cart = async (cart) => {
  const tempCart = {
    ...cart,
    instructions: cart.instructions || "",
    bookingAddress: cart.bookingAddress || "",
    freebie: cart.freebie || {},
    readyTime: cart.readyTime,
    arrivingTime: cart.arrivingTime,
    celebrationDate: cart.celebrationDate,
  };

  localStorage.setItem("LC_TEMP_CART", JSON.stringify(tempCart));

  return tempCart;
};

// it will construct the temp cart on updating cart
const constructing_temp_cart = async ({
  tempCart,
  hubId,
  addOns,
  instructions,
  offers,
  celebratingFor,
  bookingDate,
  bookingAddress,
  bookingTime,
  freebie,
  convenienceFee,
  readyTime,
  arrivingTime,
  celebrationDate,
  santaTip,
}) => {
  if (!hubId) return tempCart;

  const addonsPrice = await calculate_addons_price(
    addOns || tempCart.addOns,
    hubId
  );
  let offerInValid = false;
  const offer =
    offers?.length > 0
      ? offers[0]?.offer
      : tempCart?.offers?.length > 0
      ? tempCart?.offers[0]?.offer
      : null;

  if (offer) {
    offerInValid = offer_min_value_invalidation_check(
      offer,
      { ...tempCart, addOns: addOns || tempCart.addOns },
      hubId
    ).invalid;
  }

  const productPriceDetail = tempCart.product?.listingPriceHubWise?.find(
    (currHub) => currHub.hub === hubId
  );

  const offerDiscount = calculate_offer_discount(
    offerInValid ? [] : offers || tempCart.offers,
    productPriceDetail?.sellingPrice
  );

  const cart = {
    santaTip: typeof santaTip === "number" ? santaTip : tempCart.santaTip || 0,
    addOns: addOns || tempCart.addOns,
    instructions:
      instructions === "" ? "" : instructions || tempCart.instructions,
    bookingDate: bookingDate || tempCart.bookingDate,
    bookingAddress:
      bookingAddress === "" ? "" : bookingAddress || tempCart.bookingAddress,
    bookingTime: bookingTime || tempCart.bookingTime,
    product: tempCart.product,
    buyingPrice: productPriceDetail?.sellingPrice + addonsPrice - offerDiscount,
    cartPrice: productPriceDetail?.costPrice + addonsPrice,
    cartPaymentAttemps: 0,
    celebratingFor: celebratingFor || tempCart.celebratingFor,
    offers: offerInValid ? [] : offers || tempCart.offers,
    freebie: freebie || tempCart.freebie,
    convenienceFee: convenienceFee || tempCart.convenienceFee,
    readyTime: readyTime || tempCart.readyTime,
    arrivingTime: arrivingTime || tempCart.arrivingTime,
    celebrationDate: celebrationDate || tempCart.celebrationDate,
  };

  localStorage.setItem("LC_TEMP_CART", JSON.stringify(cart));
  return cart;
};

/**
 *calculate the complete addons discounted price
 *@async
 *@param {Object[]} addOns - An array of addons objects to find the price.
 *@returns- total addons discounted amount.
 */
const calculate_addons_price = (addOns, hubId) => {
  if (!addOns || addOns.length === 0) return 0;
  const totalAddonPrice = addOns.reduce((total, addon) => {
    const addonPriceDetail = addon.addon.listingPriceHubWise?.find(
      (currHub) => currHub.hub === hubId
    );
    const addonPrice =
      addon.text !== "" || addon.text.length !== 0
        ? addonPriceDetail?.sellingPrice * addon.text.replace(/\s/g, "").length
        : addonPriceDetail?.sellingPrice * addon.quantity;
    return total + addonPrice;
  }, 0);

  return totalAddonPrice;
};

/**
 *Calculates the discount amount for a given offer on a cart of products and addons.
 *@async
 *@param {Object[]} offers - An array of offer objects to check against the cart.
 *@param {number} cartPrice - The total price of the cart before any discounts are applied.
 *@returns - The discount amount to apply to the cart, based on the given offer(s).
 */
const calculate_offer_discount = (offers, cartPrice) => {
  let offerDiscount = 0;

  if (offers?.length > 0) {
    const offer = offers?.[0]?.offer || offers?.[0];
    if (offer) {
      const { discountType, discountValue, maxOfferAmountGain } = offer;
      if (
        offer.isRange2 &&
        offer.range2MinOrderValue <= cartPrice &&
        offer.range2MaxOrderValue >= cartPrice
      ) {
        if (offer?.range2DiscountType === 0) {
          // Percentage discount for range2
          const discount = Math.round(
            (cartPrice * offer?.range2DiscountValue) / 100
          );
          offerDiscount = Math.min(discount, offer.range2MaxPriceGain);
        } else {
          // Amount discount for range2
          offerDiscount = discountValue;
        }
      } else {
        if (discountType === 0) {
          // Percentage discount
          const discount = Math.round((cartPrice * discountValue) / 100);
          offerDiscount = Math.min(discount, maxOfferAmountGain); //
        } else {
          // Amount discount
          offerDiscount = discountValue;
        }
      }
    }
  }
  // console.log("fsdjfajs", offers, cartPrice, offerDiscount);

  return offerDiscount;
};

// temp cart structuring in order to send to backend
const temp_cart_structuring = async (tempCart) => {
  const { addOns, bookingAddress, product, offers, freebie, convenienceFee } =
    tempCart;

  const structuredAddOns = addOns.map((addon) => {
    return {
      addon: addon.addon._id,
      quantity: addon.quantity,
      text: addon.text,
      color: addon.color,
    };
  });

  const structuredOffers = offers?.map((offer) => {
    return { offer: offer.offer._id };
  });

  const structuredCart = {
    ...tempCart,
    convenienceFee: convenienceFee || 0,
    addOns: structuredAddOns,
    bookingAddress: bookingAddress._id || "",
    product: product?._id,
    offers: structuredOffers,

    freebie: freebie && Object.keys(freebie).length > 0 ? freebie._id : "",
  };
  return structuredCart;
};

const calculate_actual_price = (tempCart, hubId) => {
  if (!tempCart) return 0;
  const productPriceDetail = tempCart?.product?.listingPriceHubWise?.find(
    (currHub) => currHub.hub === hubId
  );

  const addOnsTotalPrice = calculate_addons_price(tempCart.addOns, hubId);

  return addOnsTotalPrice + productPriceDetail?.costPrice;
};

const calculate_total_savings = (tempCart, hubId) => {
  if (!tempCart) return 0;
  const productPriceDetail = tempCart?.product?.listingPriceHubWise?.find(
    (currHub) => currHub.hub === hubId
  );
  const addOnsSavings = tempCart.addOns.reduce((total, addon) => {
    const addonPriceDetail = addon.addon.listingPriceHubWise?.find(
      (currHub) => currHub.hub === hubId
    );
    return (
      total +
      addonPriceDetail?.costPrice * addon.quantity -
      addonPriceDetail?.sellingPrice * addon.quantity
    );
  }, 0);

  const totalSavings =
    addOnsSavings +
    productPriceDetail?.costPrice -
    productPriceDetail?.sellingPrice;

  const couponDiscount = calculate_offer_discount(
    tempCart.offers,
    productPriceDetail?.sellingPrice
  );

  return totalSavings + couponDiscount;
};

const offer_min_value_invalidation_check = (offer, tempCart, hubId) => {
  const productPriceDetail = tempCart?.product?.listingPriceHubWise?.find(
    (currHub) => currHub.hub === hubId
  );

  const addonPrice = calculate_addons_price(tempCart.addOns, hubId);

  const price = productPriceDetail?.sellingPrice + addonPrice;

  if (offer.minOrderValue <= price) {
    return {
      invalid: false,
    };
  }

  if (
    offer.isRange2 &&
    offer.range2MinOrderValue <= price &&
    offer.range2MaxOrderValue >= price
  ) {
    return {
      invalid: false,
    };
  }

  if (offer.isRange2 && offer.range2MinOrderValue > price) {
    return {
      invalid: true,
      moneyNeededToGetOffer: offer.range2MinOrderValue - price,
    };
  }

  if (offer.minOrderValue > price) {
    return {
      invalid: true,
      moneyNeededToGetOffer: offer.minOrderValue - price,
    };
  }
  return {
    invalid: false,
  };
};

export {
  create_temp_cart,
  constructing_temp_cart,
  calculate_addons_price,
  calculate_offer_discount,
  temp_cart_structuring,
  existing_cart_to_temp_cart,
  calculate_actual_price,
  offer_min_value_invalidation_check,
  calculate_total_savings,
};

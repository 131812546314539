import eboUtils from "../../utils/eboUtils";
import { getDistance } from "geolib";

export const handleDefaultLocationFetch = async (locationData, ipInfo) => {
  try {
    const LC_TEMP_CART = JSON.parse(localStorage?.getItem("LC_TEMP_CART"));

    // Retrieve location from localStorage
    const localstorageLocation = JSON.parse(localStorage.getItem("U_LOCATION"));
    // Set selectedLocation based on localStorage or IP geolocation
    let selectedLocation;
    let isDefaultAddress = false;

    if (locationData.selectedLocation?.lat) {
      selectedLocation = locationData.selectedLocation;
    } else if (LC_TEMP_CART?.bookingAddress?.locationv2?.lat) {
      selectedLocation = LC_TEMP_CART?.bookingAddress?.locationv2;
    } else if (localstorageLocation?.lat) {
      selectedLocation = localstorageLocation;
    } else {
      const serviceableCities = await eboUtils().getServiceableCities();

      let nearestServiceableCity = serviceableCities["mumbai"];
      let nearestDistance = null;
      if (ipInfo?.lat) {
        for (const currCityObject of Object.values(serviceableCities)) {
          const distance = getDistance(
            { latitude: ipInfo.lat, longitude: ipInfo.lng },
            {
              latitude: currCityObject.location.lat,
              longitude: currCityObject.location.lng,
            }
          );

          if (nearestDistance === null || distance < nearestDistance) {
            nearestServiceableCity = currCityObject;
            nearestDistance = distance;
          }
        }
      }

      isDefaultAddress = true;
      selectedLocation = {
        ...nearestServiceableCity,
        lat: nearestServiceableCity.location.lat,
        lng: nearestServiceableCity.location.lng,
        img: nearestServiceableCity.imageUrl,
        addressLine1: nearestServiceableCity.label,
        addressLine2: nearestServiceableCity.label + " city",
      };
    }

    let serviceAreaInfo = await eboUtils().getServiceAreaInfo({
      lat: selectedLocation.lat,
      lng: selectedLocation.lng,
    });

    // Update localStorage and state with the selected location
    localStorage.setItem(
      "U_LOCATION",
      JSON.stringify({ ...selectedLocation, isDefault: isDefaultAddress })
    );

    return {
      hubId: serviceAreaInfo.hubs?.[0]?.hubId,
      locationObject: {
        ...selectedLocation,
        hubId: serviceAreaInfo.hubs?.[0]?.hubId,
      },
    };
  } catch (error) {
    console.error(error);
  }
};
